import { Types } from "../constants/actionTypes";
  
import AdminService from "../services/admin.service";
  
  
  export const submitReviewDetail = (username, password) => (dispatch) => {
    console.log("xxxx")
    return AdminService.submitReview(username, password).then(
      (data) => {
        if(data.status=="success"){
            dispatch({
              type: Types.REVIEW_SUBMITTED,
              payload: { reviews: data }
            });
            return Promise.resolve();
        }
    },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: Types.LOGIN_FAIL,
        });
  
        dispatch({
          type: Types.SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  export const saveScrapItem = (scrapItems) => (dispatch) => {
    console.log("xxxx")
    return AdminService.addScrapItem(scrapItems).then(
      (data) => {
        if(data.payload){
            dispatch({
              type: Types.ADD_SCRAP,
              payload: { reviews: data }
            });
            return Promise.resolve();
        }
    },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: Types.LOGIN_FAIL,
        });
  
        dispatch({
          type: Types.SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  
  export const markReviewDone = () => (dispatch) => {
    dispatch({
        type: Types.REVIEW_DONE,
        payload: { reviews: 'done' }
      });
  }
  export const getAllReviews =  () => (dispatch) => {
    return AdminService.getAllReviews().then(
      (data) => {
        console.log("here",data)
        if(data.success==true){
            dispatch({
              type: Types.GET_REVIEWS,
              payload: { reviews: data.payload }
            });
            return Promise.resolve();
        }
    },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: Types.LOGIN_FAIL,
        });
  
        dispatch({
          type: Types.SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }
  export const getPendingOrders =()=>(dispatch) => {
    return AdminService.getPendingOrders().then(data =>{
      if(data.success==true){
        console.log('dasdasd1',data.payload)
        dispatch({
          type: Types.PENDING_ORDERS,
          payload: { allPendingOrders: data.payload }
        })
      }
      
    },(error)=>{
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: Types.LOGIN_FAIL,
      });

      dispatch({
        type: Types.SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
  }

  export const getCompletedOrders =()=>(dispatch) => {
    return AdminService.getCompletedOrders().then(data =>{
      if(data.success==true){
        console.log('dasdasd1',data.payload)
        dispatch({
          type: Types.PENDING_ORDERS,
          payload: { allPendingOrders: data.payload }
        })
      }
      
    },(error)=>{
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: Types.LOGIN_FAIL,
      });

      dispatch({
        type: Types.SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
  }

  export const getCancelledOrders =()=>(dispatch) => {
    return AdminService.getCancelledOrders().then(data =>{
      if(data.success==true){
        console.log('dasdasd1',data.payload)
        dispatch({
          type: Types.PENDING_ORDERS,
          payload: { allPendingOrders: data.payload }
        })
      }
      
    },(error)=>{
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: Types.LOGIN_FAIL,
      });

      dispatch({
        type: Types.SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
  }


  export const updateOrderStatus =(orderId, updatedFields)=>(dispatch) => {
    return AdminService.updateOrderStatus(orderId, updatedFields).then(data =>{
      if(data.success==true){
        console.log('dasdasd1',data.payload)
        dispatch({
          type: Types.UPDATE_ORDER_STATUS,
          payload: {
            id: orderId,
            updatedFields: data.data,
          },
        });
      }
      
    },(error)=>{
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: Types.LOGIN_FAIL,
      });

      dispatch({
        type: Types.SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
  }
  export const getUsersList =()=>(dispatch) =>{
    return AdminService.getUsersList().then(data =>{
      if(data.success==true){
        console.log("asd",data.payload)
        dispatch({ type: Types.FETCH_USERS_SUCCESS, payload: data.payload });
      }
      
    },(error)=>{
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: Types.LOGIN_FAIL,
      });

      dispatch({
        type: Types.SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
  }

  export const fetchScrapItems =()=>(dispatch)=>{
    return AdminService.fetchScrapItems().then(data =>{
      if(data.success==true){
        // console.log("asd",data.payload)
        dispatch({ type: Types.FETCH_SCRAP_ITEMS_SUCCESS, payload: data.payload });
      }
      
    },(error)=>{
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: Types.LOGIN_FAIL,
      });

      dispatch({
        type: Types.SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
  }
  export const updateUserInformation = (orderId, updatedFields)=>(dispatch) => {
    return AdminService.updateUserInformation(orderId, updatedFields).then(data =>{
      if(data.success==true){
        dispatch({ type: Types.UPDATE_USER_SUCCESS, payload: data.data });
      }
      
    },(error)=>{
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: Types.LOGIN_FAIL,
      });

      dispatch({
        type: Types.SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
  }

  export const updateScrapItem =(orderId, updatedFields)=>(dispatch) => {
    return AdminService.updateScrapItem(orderId, updatedFields).then(data =>{
      if(data.success==true){
        console.log('dasdasd1',data.payload)
        dispatch({
          type: Types.UPDATE_SCRAP_ITEM_SUCCESS,
          payload: data.data,
        });
      }
      
    },(error)=>{
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: Types.LOGIN_FAIL,
      });

      dispatch({
        type: Types.SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
  }