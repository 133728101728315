import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { getUsersList, updateUserInformation } from '../../actions/admin'; // Assuming actions to get users and update information
import { Header, Sidebar, Footer } from '../../components';
import UserDetailsModal from '../Modal/UserDetailsModal'; // Modal for viewing and updating user info

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: null, // Store selected user for viewing/updating details
    };
  }

  handleViewUser = (user) => {
    this.setState({ selectedUser: user });
  };

  handleCloseModal = () => {
    this.setState({ selectedUser: null });
  };

  handleUpdateUserInfo = (userId, updatedFields, successCallback) => {
    this.props.updateUserInformation(userId, updatedFields)
      .then(() => {
        if (successCallback) successCallback(); // Show success alert
      })
      .catch((error) => {
        console.error('Error updating user:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to update user!',
          text: 'Please try again later.',
          showConfirmButton: true,
        });
      });
  };

  componentDidMount() {
    this.props.getUsersList(); // Fetch list of users on mount
  }

  render() {
    const { allUsers } = this.props; // Assuming the users list is in the Redux state
    const { selectedUser } = this.state;

    return (
      <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">Users List</h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Users</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Data table
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Users</h4>
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>User Name</th>
                              <th>Phone No.</th>
                              <th>Email</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allUsers && allUsers.map((user) => (
                              <tr key={user.id}>
                                <td>{user.name}</td>
                                <td>{user.phone_no}</td>
                                <td>{user.email}</td>
                                <td>
                                  <label className={`badge ${user.is_active === 1 ? 'badge-success' : 'badge-warning'}`}>
                                    { `${user.is_active === 1 ? 'Active' : 'Inactive'}`}
                                  </label>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-outline-primary"
                                    onClick={() => this.handleViewUser(user)}
                                  >
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {selectedUser && (
              <UserDetailsModal
                user={selectedUser}
                onClose={this.handleCloseModal}
                onUpdateUserInfo={this.handleUpdateUserInfo}
              />
            )}
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allUsers: state.admin.users, // Assuming users are stored in this state
});

const mapDispatchToProps = {
  getUsersList, // Action to fetch the users list
  updateUserInformation, // Action to update user information
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
