import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { getPendingOrders, updateOrderStatus } from '../../../actions/admin';
import { Header, Sidebar, Footer } from '../../../components';
import BookingModal from '../Modal/BookingModal';

class PendingOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrder: null,
    };
  }

  handleViewOrder = (order) => {
    this.setState({ selectedOrder: order });
  };

  handleCloseModal = () => {
    this.setState({ selectedOrder: null });
  };

  handleUpdateStatus = (orderId, updatedFields, successCallback) => {
    this.props.updateOrderStatus(orderId, updatedFields)
      .then(() => {
        if (successCallback) successCallback(); // Show success alert
      })
      .catch((error) => {
        console.error('Error updating order:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to update order!',
          text: 'Please try again later.',
          showConfirmButton: true,
        });
      });
  };

  componentDidMount() {
    this.props.getPendingOrders();
  }

  render() {
    const { allPendingOrders } = this.props;
    const { selectedOrder } = this.state;

    return (
      <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">Pending Orders</h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Tables</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Data table
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Pending Orders</h4>
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Customer Name</th>
                              <th>Phone No.</th>
                              <th>Customer Address</th>
                              <th>Booking Date</th>
                              <th>Estimated Weight</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allPendingOrders &&
                              allPendingOrders.map((order) => (
                                <tr key={order.id}>
                                  <td>{order.user_detail.name}</td>
                                  <td>{order.user_detail.phone_no}</td>
                                  <td>{order.user_detail.address}</td>
                                  <td>{order.booking_date}</td>
                                  <td>{order.estimated_weight}</td>
                                  <td>
                                    <label className="badge badge-info">
                                      {order.status}
                                    </label>
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-outline-primary"
                                      onClick={() => this.handleViewOrder(order)}
                                    >
                                      View
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {selectedOrder && (
              <BookingModal
                order={selectedOrder}
                onClose={this.handleCloseModal}
                onUpdateStatus={this.handleUpdateStatus}
              />
            )}
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allPendingOrders: state.admin.allPendingOrders,
});

const mapDispatchToProps = {
  getPendingOrders,
  updateOrderStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingOrders);
