import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCompletedOrders } from '../../../actions/admin'; 
import { Header, Sidebar, Footer } from '../../../components';
import BookingModal from '../Modal/BookingModal'; // Import the modal
class CompletedOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errors: {
        username: 'Enter User Name!',
        password: 'Enter Password!',
      },
      loginStatus: '',
      submitted: false,
      selectedOrder: null,
    };
  }
  handleViewOrder = (order) => {
    this.setState({ selectedOrder: order });
  };

  handleCloseModal = () => {
    this.setState({ selectedOrder: null });
  };

  handleUpdateStatus = (orderId, status) => {
    //this.props.updateOrderStatus(orderId, status);
  };
  componentDidUpdate(prevProps) {
    // If the user is not logged in, redirect to login (uncomment and handle redirection properly)
    if (!this.props.isLoggedIn) {
      // this.props.history.push('/login');
    }
  }

  componentDidMount() {
    // Dispatch action to fetch pending orders
    this.props.getCompletedOrders();
  }

  render() {
    const { allPendingOrders } = this.props; // assuming pendingOrders, loading, and error are part of the Redux state
    const { selectedOrder } = this.state;
    return (
      <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">Completed Orders</h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Tables</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Data table
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Pending Orders</h4>

                
                  <div className="row">
                    <div className="col-12">
                      <div
                        id="order-listing_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        {/* Table with orders */}
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div
                              className="dataTables_length"
                              id="order-listing_length"
                            >
                              <label>
                                Show{' '}
                                <select
                                  name="order-listing_length"
                                  aria-controls="order-listing"
                                  className="custom-select custom-select-sm form-control"
                                >
                                  <option value="5">5</option>
                                  <option value="10">10</option>
                                  <option value="15">15</option>
                                  <option value="-1">All</option>
                                </select>{' '}
                                entries
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <div
                              id="order-listing_filter"
                              className="dataTables_filter"
                            >
                              <label>
                                <input
                                  type="search"
                                  className="form-control"
                                  placeholder="Search"
                                  aria-controls="order-listing"
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* Orders Table */}
                        <div className="row">
                          <div className="col-sm-12">
                            <table
                              id="order-listing"
                              className="table dataTable no-footer"
                              aria-describedby="order-listing_info"
                            >
                              <thead>
                                <tr>
                                  <th>Customer Name</th>
                                  <th>Phone No.</th>
                                  <th>Customer Address</th>
                                  <th>Booking Date</th>
                                  <th>Estimated Weight</th>
                                  <th>Status</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allPendingOrders &&
                                  allPendingOrders.map((order) => (
                                    <tr key={order.id}>
                                      <td>{order.user_detail.name}</td>
                                      <td>{order.user_detail.phone_no}</td>
                                      <td>{order.user_detail.address}</td>
                                      <td>{order.booking_date}</td>
                                      <td>{order.estimated_weight}</td>
                                      <td>
                                        <label className="badge" style={{ color: 'black' }} >
                                          {order.status}
                                        </label>
                                      </td>
                                      <td>
                                        <button className="btn btn-outline-primary" onClick={() => this.handleViewOrder(order)}>
                                          View
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* Pagination and other footer controls */}
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            <div
                              className="dataTables_info"
                              id="order-listing_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 1 to 10 of 10 entries
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="order-listing_paginate"
                            >
                              <ul className="pagination">
                                <li className="paginate_button page-item previous disabled">
                                  <a href="#" className="page-link">
                                    Previous
                                  </a>
                                </li>
                                <li className="paginate_button page-item active">
                                  <a href="#" className="page-link">
                                    1
                                  </a>
                                </li>
                                <li className="paginate_button page-item next disabled">
                                  <a href="#" className="page-link">
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {selectedOrder && (
                <BookingModal
                  order={selectedOrder}
                  onClose={this.handleCloseModal}
                  onUpdateStatus={this.handleUpdateStatus}
                />
              )}
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isLoggedIn: state.auth.isLoggedIn,
  allPendingOrders: state.admin.allPendingOrders, // Assuming orders are stored in this state
  //loading: state.orders.loading, // Add loading state if you have it
  //error: state.orders.error, // Add error handling if you have it
});

const mapDispatchToProps = {
  getCompletedOrders, // Map the action creator here
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletedOrders);
