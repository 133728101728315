export const Types = {
  LOGIN: 'LOGIN',
  ADD_USER: 'ADD_USER',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
  FORM_SUBMITION_STATUS: 'FORM_SUBMITION_STATUS',
  LOGIN_SUCCESS:'LOGIN_SUCCESS',
  LOGIN_FAIL:'LOGIN_FAIL',
  LOGOUT:'LOGOUT',
  SET_MESSAGE:'SET_MESSAGE',
  REVIEW_SUBMITTED:'REVIEW_SUBMITTED',
  REVIEW_DONE:'REVIEW_DONE',
  ADD_SCRAP:'ADD_SCRAP',
  GET_REVIEWS:'GET_REVIEWS',
  PENDING_ORDERS:'PENDING_ORDERS',
  UPDATE_ORDER_STATUS:'UPDATE_ORDER_STATUS',
  UPDATE_USER_SUCCESS:'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE:'UPDATE_USER_FAILURE',
  FETCH_USERS_SUCCESS:'FETCH_USERS_SUCCESS',
  FETCH_SCRAP_ITEMS_SUCCESS:'FETCH_SCRAP_ITEMS_SUCCESS'
}
