import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { Header, Sidebar, Footer } from '../../../components';
import { submitReviewDetail, markReviewDone, getAllReviews, deleteReview, editReview } from '../../../actions/admin';

export class Reviews extends Component {
  constructor(props) {
    super(props);
    this.addReview = this.addReview.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      reviewer_name: '',
      review_text: '',
      review_id: null,
      show: false,
      isEditing: false,
      errors: {
        reviewer_name: 'Enter Name!',
        review_text: 'Enter Review!'
      },
      submitted: false
    };
  }

  inputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.validationErrorMessage(event);
  };

  validationErrorMessage = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'reviewer_name':
        errors.reviewer_name = value.length < 1 ? 'Enter User Name' : '';
        break;
      case 'review_text':
        errors.review_text = value.length < 1 ? 'Enter Review' : '';
        break;
      default:
        break;
    }
    this.setState({ errors });
  };

  componentDidMount() {
    this.props.getAllReviews();
  }

  validateForm = (errors) => {
    let valid = true;
    Object.entries(errors).forEach(item => {
      item && item[1].length > 0 && (valid = false);
    });
    return valid;
  };

  submitReview = async (event) => {
    this.setState({ submitted: true });
    event.preventDefault();
    if (this.validateForm(this.state.errors)) {
      if (this.state.isEditing) {
        this.props.editReview(this.state.review_id, this.state.review_text, this.state.reviewer_name);
      } else {
        this.props.submitReviewDetail(this.state.review_text, this.state.reviewer_name);
      }
    } else {
      console.log('Invalid Form');
    }
  };

  componentDidUpdate() {
    if (this.props.reviewSubmitted === true) {
      if (this.state.show === true) {
        this.setState({ show: false, isEditing: false });
      }
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "success",
        title: this.state.isEditing ? "Review has been updated successfully" : "Review has been added successfully"
      });
      this.props.markReviewDone();
    }
  }

  handleClose() {
    this.setState({ show: false, isEditing: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  addReview() {
    this.setState({ reviewer_name: '', review_text: '', isEditing: false });
    this.handleShow();
  }

  editReview(reviewId, reviewerName, reviewText) {
    this.setState({ review_id: reviewId, reviewer_name: reviewerName, review_text: reviewText, isEditing: true });
    this.handleShow();
  }

  deleteReview(reviewId) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deleteReview(reviewId);
        Swal.fire(
          'Deleted!',
          'Your review has been deleted.',
          'success'
        );
      }
    });
  }

  render() {
    const { reviewer_name, review_text, submitted, errors, isEditing } = this.state;
    const { reviews } = this.props; // Assuming `reviews` is coming from props
    console.log("hello1",reviews)
    return (
      <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title"> Reviews </h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Tables</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Data table</li>
                  </ol>
                </nav>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2">
                      <button className="btn btn-outline-primary" onClick={this.addReview}>
                        Add Review
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <table id="review-listing" className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Reviewer</th>
                            <th>Review</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reviews && reviews.map((review, index) => (
                            <tr key={review.id}>
                              <td>{index + 1}</td>
                              <td>{review.reviewer_name}</td>
                              <td>{review.review_text}</td>
                              <td>
                                <button className="btn btn-outline-warning" onClick={() => this.editReview(review.id, review.reviewer_name, review.review_text)}>
                                  Edit
                                </button>
                                <button className="btn btn-outline-danger" onClick={() => this.deleteReview(review.id)}>
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? 'Edit Review' : 'Add Review'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-group">
                <label htmlFor="reviewer_name">Name:</label>
                <input
                  type="text"
                  className="form-control"
                  id="reviewer_name"
                  name="reviewer_name"
                  value={reviewer_name}
                  onChange={this.inputChange}
                />
                {submitted && errors.reviewer_name && <span className="text-danger">{errors.reviewer_name}</span>}
              </div>
              <div className="form-group">
                <label htmlFor="review_text">Review:</label>
                <textarea
                  className="form-control"
                  id="review_text"
                  name="review_text"
                  value={review_text}
                  onChange={this.inputChange}
                ></textarea>
                {submitted && errors.review_text && <span className="text-danger">{errors.review_text}</span>}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.submitReview}>
              {isEditing ? 'Save Changes' : 'Add Review'}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isLoggedIn: state.auth.isLoggedIn,
  reviews: state.admin.reviews, // Assuming reviews are stored in `state.admin.reviews`
  reviewSubmitted: state.admin.reviewSubmitted
});

const mapDispatchToProps = {
  submitReviewDetail,
  markReviewDone,
  getAllReviews,
  //deleteReview,
  //editReview
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Reviews));
