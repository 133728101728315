import { Types } from "../constants/actionTypes";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  reviewSubmitted: false,
  reviews: [], // Ensure reviews is initialized as an empty array
  allPendingOrders:[],
  users:[],
  scrapItems:[]
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.REVIEW_SUBMITTED:
      return {
        ...state,
        reviewSubmitted: true,
        reviews: [...state.reviews, payload.reviews.data], // Use state.reviews instead of state.reviewsList
      };

    case Types.ADD_SCRAP:
      return {
        ...state,
        reviewSubmitted: true,
      };

    case Types.REVIEW_DONE:
      return {
        ...state,
        reviewSubmitted: false,
      };

    case Types.GET_REVIEWS:
      return {
        ...state,
        reviews: [...state.reviews, ...payload.reviews], // Spread the reviews correctly
      };
    case Types.PENDING_ORDERS:
      console.log('ahi yaay',payload.allPendingOrders)
      return {
        ...state,
        allPendingOrders: [...state.allPendingOrders, ...payload.allPendingOrders], // Spread the reviews correctly
      };
    case Types.UPDATE_ORDER_STATUS:
        return {
          ...state,
          allPendingOrders: state.allPendingOrders.map((order) =>
            order.id === action.payload.id ? { ...order, ...action.payload.updatedFields } : order
          ),
        };
    case Types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case Types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };
    case Types.FETCH_SCRAP_ITEMS_SUCCESS:
      return {
        ...state,
        scrapItems: action.payload,
      };
    default:
      return state;
  }
}
