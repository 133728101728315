import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

const UserDetailsModal = ({ user, onClose, onUpdateUserInfo }) => {
  const [name, setName] = useState(user.name);
  const [phone_no, setPhoneNo] = useState(user.phone_no);
  const [email, setEmail] = useState(user.email);
  const [status, setStatus] = useState(user.status);

  useEffect(() => {
    // Initialize the state with user details
    setName(user.name);
    setPhoneNo(user.phone_no);
    setEmail(user.email);
    setStatus(user.status);
  }, [user]);

  const handleSave = () => {
    const updatedFields = {
      name,
      phone_no,
      email,
      status,
    };

    onUpdateUserInfo(user.id, updatedFields, () => {
      Swal.fire({
        icon: 'success',
        title: 'User updated successfully!',
        text: 'The user information has been updated.',
        showConfirmButton: false,
        timer: 1500,
      });
    });

    onClose();
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <h3>Edit User Details</h3>
        <div>
          <form>
            <div className="form-group">
              <label>User Name</label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Phone No.</label>
              <input
                type="text"
                className="form-control"
                value={phone_no}
                onChange={(e) => setPhoneNo(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Status</label>
              <select
                className="form-control"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
          </form>
        </div>

        <div className="modal-actions">
          <button className="btn btn-secondary" onClick={onClose}>
            Close
          </button>
          <button className="btn btn-primary" onClick={handleSave}>
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsModal;
