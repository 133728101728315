import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import './Modal.css';

const BookingModal = ({ order, onClose, onUpdateStatus }) => {
  const [activeTab, setActiveTab] = useState('details');
  const [status, setStatus] = useState(order.status);
  const [paymentStatus, setPaymentStatus] = useState(order.payment_status || 'Pending');
  const [pickupPerson, setPickupPerson] = useState(order.pickupPerson || '');
  const [pickupDate, setPickupDate] = useState(order.pickupDate || '');
  const [pickupTime, setPickupTime] = useState(order.pickupTime || '');
  const printRef = useRef();

  const handleSave = () => {
    const updatedFields = {
      status,
      payment_status: paymentStatus,
      pickupPerson,
      booking_date: pickupDate,
      booking_time: pickupTime,
    };
  
    // Remove fields with empty values
    const filteredFields = Object.fromEntries(
      Object.entries(updatedFields).filter(([_, value]) => value !== '')
    );
  
    onUpdateStatus(order.id, filteredFields, () => {
      Swal.fire({
        icon: 'success',
        title: 'Order updated successfully!',
        text: 'The order status has been updated.',
        showConfirmButton: false,
        timer: 1500, // Close the alert after 1.5 seconds
      });
    });
  
    onClose();
  };
  
  

  const handlePrint = () => {
    const printContent = printRef.current.innerHTML;
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Invoice</title></head><body>');
    printWindow.document.write(printContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <h3>Order Management</h3>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'details' ? 'active' : ''}`}
            onClick={() => setActiveTab('details')}
          >
            Order Details
          </button>
          <button
            className={`tab ${activeTab === 'payment' ? 'active' : ''}`}
            onClick={() => setActiveTab('payment')}
          >
            Payment Info
          </button>
          <button
            className={`tab ${activeTab === 'pickup' ? 'active' : ''}`}
            onClick={() => setActiveTab('pickup')}
          >
            Pickup Info
          </button>
        </div>

        <div className="tab-content">
          {activeTab === 'details' && (
            <div>
              <h4>Order Details</h4>
              <table className="modal-table">
                <tbody>
                  <tr>
                    <th>Customer Name</th>
                    <td>{order.user_detail.name}</td>
                  </tr>
                  <tr>
                    <th>Phone No.</th>
                    <td>{order.user_detail.phone_no}</td>
                  </tr>
                  <tr>
                    <th>Customer Address</th>
                    <td>{order.user_detail.address}</td>
                  </tr>
                  <tr>
                    <th>Booking Date</th>
                    <td>{order.booking_date}</td>
                  </tr>
                  <tr>
                    <th>Estimated Weight</th>
                    <td>{order.estimated_weight}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                      <select value={status} onChange={handleStatusChange}>
                        <option value="Pending">Pending</option>
                        <option value="Completed">Completed</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {activeTab === 'payment' && (
            <div>
              <h4>Payment Info</h4>
              <table className="modal-table">
                <tbody>
                  <tr>
                    <th>Payment Status</th>
                    <td>
                      <select
                        value={paymentStatus}
                        onChange={(e) => setPaymentStatus(e.target.value)}
                      >
                        <option value="Pending">Pending</option>
                        <option value="Paid">Paid</option>
                        <option value="Failed">Failed</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>Amount</th>
                    <td>{order.amount || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th>Payment Method</th>
                    <td>{order.payment_method || 'N/A'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {activeTab === 'pickup' && (
            <div>
              <h4>Pickup Info</h4>
              <table className="modal-table">
                <tbody>
                  <tr>
                    <th>Pickup Person</th>
                    <td>
                      <input
                        type="text"
                        value={pickupPerson}
                        onChange={(e) => setPickupPerson(e.target.value)}
                        placeholder="Enter pickup person's name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Pickup Date</th>
                    <td>
                      <input
                        type="date"
                        value={pickupDate}
                        onChange={(e) => setPickupDate(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Pickup Time</th>
                    <td>
                      <input
                        type="time"
                        value={pickupTime}
                        onChange={(e) => setPickupTime(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="modal-actions">
          <button className="btn btn-secondary" onClick={onClose}>
            Close
          </button>
          <button className="btn btn-primary" onClick={handleSave}>
            Save Changes
          </button>
          <button className="btn btn-outline-success" onClick={handlePrint}>
            Print Invoice
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;
