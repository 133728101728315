import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { Header, Sidebar, Footer } from '../../../components';
import { saveScrapItem, markReviewDone, fetchScrapItems, updateScrapItem } from '../../../actions/admin';
class ScrapList extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getCategoryName = this.getCategoryName.bind(this);
    this.state = {
      category: '',
      scrap_name: '',
      scrap_icon: null,
      scrap_price: '',
      scrap_type: '',
      errors: {
        category: "Please select category",
        scrap_name: "Enter Scrap Name",
        scrap_icon: "Enter Scrap Icon",
        scrap_price: "Enter Scrap Price",
        scrap_type: "Please select scrap type"
      },
      submitted: false,
      show: false,
      editMode: false,  // New state for edit mode
      selectedScrapItem: null,  // State to store the item being edited
    };
  }

  componentDidMount() {
    // Fetch scrap items when the component mounts
    this.props.fetchScrapItems();
  }

  inputChange = (event) => {
    const { name, value, files } = event.target;

    if (name === 'scrap_icon' && files.length > 0) {
      this.setState({ [name]: files[0] });
    } else {
      this.setState({ [name]: value });
    }

    this.validationErrorMessage(event);
  }

  validationErrorMessage = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'category':
        errors.category = value.length < 1 ? 'Please select category' : '';
        break;
      case 'scrap_name':
        errors.scrap_name = value.length < 1 ? 'Enter Scrap Name' : '';
        break;
      case 'scrap_icon':
        errors.scrap_icon = value ? '' : 'Enter Scrap Icon';
        break;
      case 'scrap_price':
        errors.scrap_price = value.length < 1 ? 'Enter Scrap Price' : '';
        break;
      case 'scrap_type':
        errors.scrap_type = value.length < 1 ? 'Please select scrap type' : '';
        break;
      default:
        break;
    }

    this.setState({ errors });
  }

  validateForm = (errors) => {
    let valid = true;
    Object.entries(errors).forEach(item => {
      item && item[1].length > 0 && (valid = false);
    });
    return valid;
  }

  submitReview = async (event) => {
    this.setState({ submitted: true });
    event.preventDefault();

    if (this.validateForm(this.state.errors)) {
      console.info('Valid Form');

      const formData = new FormData();
      formData.append('category', this.state.category);
      formData.append('scrap_name', this.state.scrap_name);
      formData.append('scrap_icon', this.state.scrap_icon);
      formData.append('scrap_price', this.state.scrap_price);
      formData.append('scrap_type', this.state.scrap_type);

      if (this.state.editMode) {
        // If in edit mode, update the scrap item
        this.props.updateScrapItem(formData, this.state.selectedScrapItem.id);
      } else {
        // If in add mode, create a new scrap item
        this.props.saveScrapItem(formData);
      }
    } else {
      console.log('Invalid Form');
    }
  }

  componentDidUpdate() {
    if (this.props.isLoggedIn === false) {
      // Redirect to login page
    }

    if (this.props.reviewSubmitted === true) {
      if (this.state.show === true) {
        this.setState({ show: false });
      }

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "success",
        title: this.state.editMode ? "Item has been updated successfully." : "Item has been added successfully."
      });

      this.props.markReviewDone();
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow(item = null) {
    if (item) {
      // If an item is passed, it's for editing
      this.setState({
        show: true,
        editMode: true,
        selectedScrapItem: item,
        category: item.category,
        scrap_name: item.scrap_name,
        scrap_icon: item.scrap_icon,
        scrap_price: item.scrap_price,
        scrap_type: item.scrap_type,
      });
    } else {
      // If no item is passed, it's for adding a new item
      this.setState({
        show: true,
        editMode: false,
        selectedScrapItem: null,
        category: '',
        scrap_name: '',
        scrap_icon: null,
        scrap_price: '',
        scrap_type: '',
      });
    }
  }

  getCategoryName = (categoryId) => {
    switch (categoryId) {
      case '1':
        return 'Normal Recyclables';
      case '2':
        return 'Large Appliances';
      case '3':
        return 'Small Appliances';
      case '4':
        return 'Mobiles & Computers';
      case '5':
        return 'Others';
      default:
        return 'Unknown Category';
    }
  };

  render() {
    const { errors, submitted } = this.state;
    const { scrapItems } = this.props;  // Get the list of scrap items from props

    return (
      <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title"> Scrap & Rate List </h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Tables</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Data table</li>
                  </ol>
                </nav>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <button className="btn btn-outline-primary" onClick={() => this.handleShow()}>
                        Add Scrap Item
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <table id="order-listing" className="table dataTable no-footer">
                        <thead>
                          <tr>
                            <th>Order #</th>
                            <th>Scrap Name</th>
                            <th>Category</th>
                            <th>Price</th>
                            <th>Type</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {scrapItems && scrapItems.map((item, index) => (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.scrap_name}</td>
                              <td>{this.getCategoryName(item.category)}</td>
                              <td>{item.scrap_price}</td>
                              <td>{item.scrap_type}</td>
                              <td>
                                <button className="btn btn-outline-warning" onClick={() => this.handleShow(item)}>Edit</button>
                                <button className="btn btn-danger">Delete</button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>{this.state.editMode ? "Edit Scrap Item" : "Add Scrap Item"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={this.submitReview} encType="multipart/form-data">
                  <div className="form-group">
                    <label className="col-form-label">Category:</label>
                    <select className="form-control" name="category" value={this.state.category} onChange={this.inputChange}>
                      <option value="">Select</option>
                      <option value="1">Normal Recyclables</option>
                      <option value="2">Large Appliances</option>
                      <option value="3">Small Appliances</option>
                      <option value="4">Mobiles & Computers</option>
                      <option value="5">Other</option>
                    </select>
                    {submitted && errors.category && <span className="error">{errors.category}</span>}
                  </div>

                  <div className="form-group">
                    <label className="col-form-label">Scrap Name:</label>
                    <input type="text" className="form-control" name="scrap_name" value={this.state.scrap_name} onChange={this.inputChange} />
                    {submitted && errors.scrap_name && <span className="error">{errors.scrap_name}</span>}
                  </div>

                  <div className="form-group">
                    <label className="col-form-label">Scrap Icon:</label>
                    <input type="file" className="form-control" name="scrap_icon" onChange={this.inputChange} accept="image/*" />
                    {submitted && errors.scrap_icon && <span className="error">{errors.scrap_icon}</span>}
                  </div>

                  <div className="form-group">
                    <label className="col-form-label">Scrap Price:</label>
                    <input type="text" className="form-control" name="scrap_price" value={this.state.scrap_price} onChange={this.inputChange} />
                    {submitted && errors.scrap_price && <span className="error">{errors.scrap_price}</span>}
                  </div>

                  <div className="form-group">
                    <label className="col-form-label">Scrap Type:</label>
                    <select className="form-control" name="scrap_type" value={this.state.scrap_type} onChange={this.inputChange}>
                      <option value="">Select</option>
                      <option value="KG">KG</option>
                      <option value="Piece">Piece</option>
                    </select>
                    {submitted && errors.scrap_type && <span className="error">{errors.scrap_type}</span>}
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.submitReview}>
                  {this.state.editMode ? "Save Changes" : "Add Item"}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isLoggedIn: state.auth.isLoggedIn,
  reviewSubmitted: state.admin.reviewSubmitted,
  scrapItems: state.admin.scrapItems,
});

const mapDispatchToProps = {
  saveScrapItem,
  markReviewDone,
  fetchScrapItems,
  updateScrapItem,  // Add action to update the scrap item
};

export default connect(mapStateToProps, mapDispatchToProps)(ScrapList);
