import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Outlet, Link } from "react-router-dom";
import { Config } from "../../config";
import './style.css';

export class Sidebar extends Component {
  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
          <ul className="nav">
            <li className="nav-item nav-profile">
              <a href="#" className="nav-link">
                <div className="nav-profile-image">
                  <img src={process.env.PUBLIC_URL+'/assets/images/faces/face1.jpg'} alt="profile"/>
                  <span className="login-status online"></span>
                </div>
                <div className="nav-profile-text d-flex flex-column">
                  <span className="font-weight-bold mb-2">{this.props.user.name}</span>
                  <span className="text-secondary text-small">SuperAdmin</span>
                </div>
                <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
              </a>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="../../index.html">
                <span className="menu-title">Dashboard</span>
                <i className="mdi mdi-home menu-icon"></i>
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                <span className="menu-title">User Manager</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-account-multiple-plus menu-icon"></i>
              </a>
              <div className="collapse" id="ui-basic">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className="nav-link" to={'/admin/users'}>Users List</Link></li>
                  <li className="nav-item"> <Link className="nav-link" to={Config.APP_URL+'/admin/deleted/users'}>Deleted Users</Link></li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="collapse" href="#order-schedules" aria-expanded="false" aria-controls="ui-basic">
                <span className="menu-title">Order Schedules</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-cart menu-icon"></i>
              </a>
              <div className="collapse" id="order-schedules">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className="nav-link" to={'/admin/pending/orders'}>Pending Schedules</Link></li>
                  <li className="nav-item"> <Link className="nav-link" to={'/admin/completed/orders'}>Completed Schedules</Link></li>
                  <li className="nav-item"> <Link className="nav-link" to={'/admin/cancelled/orders'}>Cancelled Schedules</Link></li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="collapse" href="#manage-website" aria-expanded="false" aria-controls="ui-basic">
                <span className="menu-title">Manage Website</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-cart menu-icon"></i>
              </a>
              <div className="collapse" id="manage-website">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className="nav-link" to={'/admin/reviews'}>Reviews</Link></li>
                  <li className="nav-item"> <Link className="nav-link" to={'/admin/scrap-list'}>Scrap Rate List</Link></li>
                  <li className="nav-item"> <Link className="nav-link" to={'/admin/brandings'}>Brandings</Link></li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="../../pages/icons/mdi.html">
                <span className="menu-title">Icons</span>
                <i className="mdi mdi-contacts menu-icon"></i>
              </Link>
            </li>
            <li className="nav-item sidebar-actions">
              <span className="nav-link">
                <div className="border-bottom">
                  <h6 className="font-weight-normal mb-3">Projects</h6>
                </div>
                <button className="btn btn-block btn-lg btn-gradient-primary mt-4">+ Add a project</button>
                
              </span>
            </li>
          </ul>
        </nav>
    )
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(Sidebar);


