import axios from "axios";
import { Config } from "../config";

//const Config.API_URL = "http://localhost:3001/api/";
const Authorization= localStorage.getItem('user')
var headers={};
if(Authorization){
  var headers = {
    'Content-Type': 'application/json',
    'Authorization': JSON.parse(Authorization).token
  }
}
const register = (username, email, password) => {
  return axios.post(Config.API_URL + "login", {
    username,
    email,
    password,
  });
};

const submitReview = (reviewer_name, review_text) => {
 
  return axios.post(Config.API_URL + "/reviews", {
    reviewer_name,
    review_text,
    },{
      headers: headers
    })
    .then((response) => {
      return response.data;
    });
};

const getAllReviews = () => {
  return axios.get(Config.API_URL + "/reviews",{
    headers: headers
  }).then((response) => {
    return response.data;
  });
}
const addScrapItem = (scrapData) => {
  return axios.post(Config.API_URL + "/scrapRates", scrapData,{
    headers: headers
  }).then((response) => {
    return response.data;
  });
}

const getPendingOrders = () =>{
  return axios.get(Config.API_URL + "/bookings/schedules?status=recieved",{
    headers: headers
  }).then((response) => {
    return response.data;
  });
}

const getCompletedOrders = () =>{
  return axios.get(Config.API_URL + "/bookings/schedules?status=done",{
    headers: headers
  }).then((response) => {
    return response.data;
  });
}

const getCancelledOrders = () =>{
  return axios.get(Config.API_URL + "/bookings/schedules?status=cancelled",{
    headers: headers
  }).then((response) => {
    return response.data;
  });
}

const  updateOrderStatus = (orderId, updatedFields)=>{
  return axios.put(`${Config.API_URL}/bookings/assign/${orderId}`, updatedFields,{
    headers: headers
  }).then((response) => {
    return response.data;
  });
}
const  updateScrapItem = (orderId, updatedFields)=>{
  return axios.put(`${Config.API_URL}/bookings/assign/${orderId}`, updatedFields,{
    headers: headers
  }).then((response) => {
    return response.data;
  });
}

const getUsersList =()=>{
  return axios.get(Config.API_URL + "/users",{
    headers: headers
  }).then((response) => {
    return response.data;
  });
}

const fetchScrapItems =()=>{
  return axios.get(Config.API_URL + "/scrapRates",{
    headers: headers
  }).then((response) => {
    return response.data;
  });
}
export default {
    submitReview,
    addScrapItem,
    getAllReviews,
    getPendingOrders,
    getCompletedOrders,
    getCancelledOrders,
    updateOrderStatus,
    getUsersList,
    fetchScrapItems,
    updateScrapItem
};